import { Button, Result } from "antd";

import routes from "../constants/routes";

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Lo siento, la página que visitaste no existe."
    extra={
      <Button type="primary" href={routes.HOME.path}>
        Regresar al inicio
      </Button>
    }
  />
);
export default NotFound;
