const routes = Object.freeze({
  SIGN_IN: {
    key: "login",
    label: "Iniciar sesión",
    path: "/login",
  },
  SIGN_OUT: {
    key: "logout",
    label: "Cerrar sesión",
    path: "/logout",
  },
  HOME: {
    key: "home",
    label: "Inicio",
    path: "/",
  },
  NOT_FOUND: {
    key: "not-found",
    path: "*",
  },
});

export default routes;
