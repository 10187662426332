import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import supabase from "../helpers/supabase";
import { setUser } from "../redux/auth/actions";

const useAuth = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data }) => {
        const { session } = data;
        dispatch(setUser(session));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [dispatch]);

  return { loading };
};

export default useAuth;
