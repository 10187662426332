import { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs";
import { isEqual } from "lodash";

import supabase from "../helpers/supabase";

const FileUpload = ({ updateDataSource }) => {
  const [loading, setLoading] = useState(false);

  const handleUpload = async (file) => {
    if (loading) return false;

    setLoading(true);

    try {
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);

      const worksheet = workbook.getWorksheet(1);
      const headers = [];

      worksheet.getRow(1).eachCell((cell) => {
        headers.push(
          cell.value.toString().trim().split(" ").join("_").toLowerCase()
        );
      });

      if (
        isEqual(headers, [
          "cve_prod",
          "desc_prod",
          "uni_med",
          "prec_prod_2",
          "lista_1",
          "sub_cse",
          "sub_subcse",
          "des_tial",
          "existencia",
        ])
      ) {
        const rows = [];

        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber === 1) return;
          const rowData = { id: rowNumber };
          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const key = headers[colNumber - 1];
            const value =
              cell.value !== null ? cell.value.toString().trim() : null;
            rowData[key] = value;
          });
          rows.push(rowData);
        });

        const jsonData = JSON.stringify(rows);

        const { data, error } = await supabase.storage
          .from("datasource")
          .upload(
            `public/productos.json`,
            new Blob([jsonData], { type: "application/json" }),
            {
              cacheControl: "3600",
              upsert: true,
            }
          );

        if (error) {
          console.error("Error uploading file:", error.message);
          message.error("Error al subir el archivo.");
        } else {
          console.log("File uploaded successfully:", data);
          message.success("Archivo subido correctamente.");
          updateDataSource();
        }
      } else {
        console.error("Invalid file format.");
        message.error("Formato de archivo inválido.");
      }
    } catch (error) {
      console.error("Error processing file:", error.message);
      message.error("Error al procesar el archivo.");
    } finally {
      setLoading(false);
    }

    return false;
  };

  return (
    <Upload accept=".xlsx" beforeUpload={handleUpload} showUploadList={false}>
      <Button icon={<UploadOutlined />} loading={loading}>
        {loading ? "Subiendo..." : "Subir productos"}
      </Button>
    </Upload>
  );
};

export default FileUpload;
