import { Table } from "antd";
import dayjs from "dayjs";

const DataTable = ({ isLoadingDataSource, dataSource }) => (
  <Table
    locale={{ emptyText: "No se ha subido ningún archivo." }}
    scroll={{ x: true }}
    pagination={{ hideOnSinglePage: true }}
    loading={isLoadingDataSource}
    rowKey="id"
    dataSource={dataSource}
    columns={[
      {
        title: "Archivo",
        dataIndex: "name",
        key: "name",
        render: (text) => <p>{text.replace(".json", "")}</p>,
      },
      {
        title: "Última modificación",
        dataIndex: "updated_at",
        key: "updated_at",
        render: (text) => <p>{dayjs(text).format("DD/MM/YYYY HH:mm")}</p>,
      },
    ]}
  />
);

export default DataTable;
