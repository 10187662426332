import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./views/Loading";
import Auth from "./views/Auth";
import Logout from "./views/Logout";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import { ProtectedRoute, PublicRoute } from "./components/Routes";
import routes from "./constants/routes";
import useAuth from "./hooks/useAuth";

const AppRouter = () => {
  const { loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route exact path={routes.HOME.path} element={<Home />} />
        </Route>
        <Route element={<PublicRoute />}>
          <Route path={routes.SIGN_IN.path} element={<Auth />} />
        </Route>
        <Route path={routes.SIGN_OUT.path} element={<Logout />} />
        <Route path={routes.NOT_FOUND.path} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
