import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { userSelector } from "../redux/auth/selectors";
import routes from "../constants/routes";

export const ProtectedRoute = () => {
  const user = useSelector(userSelector);

  if (user) {
    return <Outlet />;
  }

  return <Navigate to={routes.SIGN_IN.path} />;
};

export const PublicRoute = () => {
  const user = useSelector(userSelector);

  if (user) {
    return <Navigate to={routes.HOME.path} />;
  }

  return <Outlet />;
};
