import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import DataTable from "../../components/DataTable";
import FileUpload from "../../components/FileUpload";
import supabase from "../../helpers/supabase";
import routes from "../../constants/routes";
import "./styles.css";

const Home = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [isLoadingDataSource, setIsLoadingDataSource] = useState(true);

  const updateDataSource = async () => {
    const { data } = await supabase.storage.from("datasource").list("public");
    setDataSource(data.filter((item) => item.name === "productos.json"));
    setIsLoadingDataSource(false);
  };

  const handleLogout = () => {
    navigate(routes.SIGN_OUT.path);
  };

  useEffect(() => {
    updateDataSource();
  }, []);

  return (
    <main>
      <div className="cta">
        <FileUpload updateDataSource={updateDataSource} />
        <Button danger onClick={handleLogout}>
          Cerrar sesión
        </Button>
      </div>
      <DataTable
        isLoadingDataSource={isLoadingDataSource}
        dataSource={dataSource}
      />
    </main>
  );
};

export default Home;
