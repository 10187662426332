import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { App, ConfigProvider } from "antd";
import locale from "antd/lib/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";

import AppRouter from "./AppRouter";
import { store, persistor } from "./redux/store";
import theme from "./constants/theme";
import "./index.css";

dayjs.locale("es-mx");

const root = createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider locale={locale} theme={theme}>
    <App>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AppRouter />
        </PersistGate>
      </Provider>
    </App>
  </ConfigProvider>
);
