import React from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Form, Input, Button, message } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { setUser } from "../../redux/auth/actions";
import supabase from "../../helpers/supabase";
import "./styles.css";

const schema = yup.object().shape({
  email: yup.string().required("El usuario es requerido"),
  password: yup.string().required("La contraseña es requerida"),
});

const Auth = () => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async ({ email, password }) => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      message.error(error.message);
      setLoading(false);
    } else {
      message.success("Inicio de sesión exitoso");
      dispatch(setUser(data.session));
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Form.Item
            htmlFor="email"
            label="Usuario"
            validateStatus={errors.email ? "error" : ""}
            help={errors.email?.message}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input disabled={loading} id="email" {...field} />
              )}
            />
          </Form.Item>
          <Form.Item
            htmlFor="password"
            label="Contraseña"
            validateStatus={errors.password ? "error" : ""}
            help={errors.password?.message}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input.Password disabled={loading} id="password" {...field} />
              )}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="full-width-button"
            >
              Iniciar sesión
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Auth;
