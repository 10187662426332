import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

import supabase from "../../helpers/supabase";
import { setUser } from "../../redux/auth/actions";
import routes from "../../constants/routes";
import "./styles.css";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth
      .signOut()
      .then(console.log)
      .catch(console.error)
      .finally(() => {
        dispatch(setUser(null));
        navigate(routes.SIGN_IN.path);
      });
  }, [dispatch, navigate]);

  return (
    <div className="container">
      <Spin />
    </div>
  );
};

export default Logout;
